import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';

const StyledWrapper = styled.div`
  display: block;
  width: 100%;
  color: ${({ theme }) => theme.color.one};
  margin: 20px 0;
  text-align: center;
  ${({ theme }) => theme.mq.lg} {
    margin: 40px 0;
  }
`;

const StyledHeader = styled.h1`
  font-size: ${({ theme }) => theme.font.size.sm};
  margin-top: 0;
  text-transform: uppercase;
  font-weight: 700;
  color: ${({ theme, color }) => color || theme.color.five};
  position: relative;
`;

const StyledLine = styled.hr`
  margin: auto;
  width: 70px;
  position: relative;
  border: 2px solid ${({ theme }) => theme.color.three};
  margin-top: 15px;
  background: ${({ theme }) => theme.color.three};
`;

const StyledSubTitle = styled.p`
  color: ${({ theme, color }) => color || theme.color.five};
  font-size: ${({ theme }) => theme.font.size.xxs};
  font-weight: 400;
  max-width: 80%;
  margin: 15px auto 0;
`;

const SectionHeader = ({ className, title, subtitle, color }) => {
  let header = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.from(header, {
      scrollTrigger: header,
      opacity: 0,
      duration: 1,
      y: 50,
    });
  }, []);
  return (
    <StyledWrapper
      ref={(el) => {
        header = el;
      }}
      className={className}
      color={color}
    >
      <StyledHeader color={color}>{title}</StyledHeader>
      <StyledLine />
      <StyledSubTitle color={color}>{subtitle}</StyledSubTitle>
    </StyledWrapper>
  );
};

SectionHeader.defaultProps = {
  color: '#363636',
  subtitle: '',
  className: '',
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default SectionHeader;
